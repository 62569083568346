/* eslint-disable */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ReactComponent as Live } from 'src/assets/images/live.svg';
import { ReactComponent as Match } from 'src/assets/images/match.svg';
import loading from 'src/assets/images/loading.gif';
import './Sport.scss';
import Input from 'src/components/inputV2/input';
import { getListSport, getContries } from 'src/core/engines/SportEngine';
import { useSelector } from 'react-redux';
import Button from 'src/components/buttonV2/Button';
import SportAccardion from 'src/components/sportAccardion';
import MenuSportAccardion from 'src/components/menuSportComponent/menuSportAccardion';
import { ICountryResource } from './type';
import Betting from 'src/components/menuSportComponent/Betting';

const Sport: FC = () => {
    const sportList = useSelector((state: { sports: { sports: any[] } }) => state.sports.sports);
    const [status, setStatus] = useState(3);
    const [countries, setCountries] = useState<ICountryResource[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [search, setSearch] = useState('');

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const loadData = async () => {
        setIsLoading(true);
        await getListSport();
        const country = await getContries();
        setCountries(country);
        setIsLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    if (isLoading)
        return (
            <div className="Sport-loading">
                <img src={loading} alt="" />
            </div>
        );

    return (
        <div className="Sport">
            <div className="Sport-menu">
                <div className="Sport-menu-input">
                    <Input placeholder="Find your game/league..." value={search} id="name" onChange={handleChangeInput} />
                </div>
                <div className="Sport-menu-content">
                    <div className="Sport-menu-content-header">
                        <div className="Sport-menu-content-header-tabs">
                            <span className="Sport-menu-content-header-tabs-active">PRE-MATCH</span>
                            <span>
                                LIVE{' '}
                                <div className="Sport-menu-content-header-tabs-live">
                                    <Live />
                                    120
                                </div>
                            </span>
                        </div>
                        <div className="Sport-menu-content-header-filters">
                            <div className="Sport-menu-content-header-filters-buttons">
                                <span className="Sport-menu-content-header-filters-buttons-active">All</span>
                                <span>Events</span>
                            </div>
                        </div>
                    </div>
                    <div className="Sport-menu-content-list">
                        {sportList.map((item) => (
                            <MenuSportAccardion name={item.name} id={item.id} countries={countries} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="Sport-content">
                <div className="Sport-content-header">
                    <span className="Sport-content-header-active">Home</span>
                    <div className="Sport-border" />
                    <span>Live Event List</span>
                    <div className="Sport-border" />
                    <span>Multi View</span>
                    <div className="Sport-border" />
                    <span>Calendar</span>
                    <div className="Sport-border" />
                    <span>Results</span>
                </div>
                <div className="Sport-content-live">
                    <div className="Sport-content-live-list">
                        <div className="Sport-content-live-list-item">
                            <div className="Sport-content-live-list-item-icon">
                                <Match />
                            </div>
                            <div className="Sport-content-live-list-item-content">
                                <div className="Sport-content-live-list-item-content-header">
                                    <span>Name-Team</span>
                                    <span> 12 JUN 03:00</span>
                                    <span>Name-Team</span>
                                </div>
                                <div className="Sport-content-live-list-item-content-info">
                                    <div className="Sport-content-live-list-item-content-info-details">
                                        <div className="Sport-content-live-list-item-content-info-details-item">
                                            <span>Win1</span>
                                            <div className="Sport-border" />
                                            <span>3.25</span>
                                        </div>
                                        <div className="Sport-content-live-list-item-content-info-details-item">
                                            <span>Draw</span>
                                            <div className="Sport-border" />
                                            <span>2.07</span>
                                        </div>
                                        <div className="Sport-content-live-list-item-content-info-details-item">
                                            <span>Win2</span>
                                            <div className="Sport-border" />
                                            <span>3.85</span>
                                        </div>
                                    </div>
                                    <Button className="Sport-content-live-list-item-content-button" title="DETAILS" primary />
                                </div>
                            </div>
                        </div>
                        <div className="Sport-content-live-list-item">
                            <div className="Sport-content-live-list-item-icon">
                                <Match />
                            </div>
                            <div className="Sport-content-live-list-item-content">
                                <div className="Sport-content-live-list-item-content-header">
                                    <span>Name-Team</span>
                                    <span> 12 JUN 03:00</span>
                                    <span>Name-Team</span>
                                </div>
                                <div className="Sport-content-live-list-item-content-info">
                                    <div className="Sport-content-live-list-item-content-info-details">
                                        <div className="Sport-content-live-list-item-content-info-details-item">
                                            <span>Win1</span>
                                            <div className="Sport-border" />
                                            <span>3.25</span>
                                        </div>
                                        <div className="Sport-content-live-list-item-content-info-details-item">
                                            <span>Draw</span>
                                            <div className="Sport-border" />
                                            <span>2.07</span>
                                        </div>
                                        <div className="Sport-content-live-list-item-content-info-details-item">
                                            <span>Win2</span>
                                            <div className="Sport-border" />
                                            <span>3.85</span>
                                        </div>
                                    </div>
                                    <Button className="Sport-content-live-list-item-content-button" title="DETAILS" primary />
                                </div>
                            </div>
                        </div>
                        <div className="Sport-content-live-list-item">
                            <div className="Sport-content-live-list-item-icon">
                                <Match />
                            </div>
                            <div className="Sport-content-live-list-item-content">
                                <div className="Sport-content-live-list-item-content-header">
                                    <span>Name-Team</span>
                                    <span> 12 JUN 03:00</span>
                                    <span>Name-Team</span>
                                </div>
                                <div className="Sport-content-live-list-item-content-info">
                                    <div className="Sport-content-live-list-item-content-info-details">
                                        <div className="Sport-content-live-list-item-content-info-details-item">
                                            <span>Win1</span>
                                            <div className="Sport-border" />
                                            <span>3.25</span>
                                        </div>
                                        <div className="Sport-content-live-list-item-content-info-details-item">
                                            <span>Draw</span>
                                            <div className="Sport-border" />
                                            <span>2.07</span>
                                        </div>
                                        <div className="Sport-content-live-list-item-content-info-details-item">
                                            <span>Win2</span>
                                            <div className="Sport-border" />
                                            <span>3.85</span>
                                        </div>
                                    </div>
                                    <Button className="Sport-content-live-list-item-content-button" title="DETAILS" primary />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Sport-content-info">
                    <div className="Sport-content-sport">
                        <div className="Sport-content-sport-header">
                            <div className="Sport-content-sport-header-title">
                                <span>LIVE BETTING</span>
                            </div>
                            <div className="Sport-content-sport-header-switch ">
                                <div
                                    className={`Sport-content-sport-header-switch-item ${status === 3 && 'active'}`}
                                    onClick={() => setStatus(3)}
                                >
                                    <span>NOW</span>
                                </div>
                                <div
                                    className={`Sport-content-sport-header-switch-item ${status === 2 && 'active'}`}
                                    onClick={() => setStatus(2)}
                                >
                                    <span>COMMING UP</span>
                                </div>
                            </div>
                        </div>
                        <div className="Sport-content-sport-list">
                            {sportList.map((item, index) => (
                                <SportAccardion name={item.name} id={item.id} status={status} index={index} />
                            ))}
                        </div>
                    </div>
                    <Betting />
                </div>
            </div>
        </div>
    );
};

export default Sport;
