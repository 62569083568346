import './LiveCasino.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getGames } from '../../core/engines/GamesEngine';
import loading from 'src/assets/images/loading.gif';
import { t } from 'i18next';
import Button from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';
import { GameTypeEnum } from 'src/common/enum';

function LiveCasino() {
    const isMobile = window.innerWidth <= 900;
    const width = window.innerWidth;
    const games = useSelector((state) => state.games.games);
    const name = useSelector((state) => state.games.name);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingGame, setIsLoadingGame] = useState('');
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const navigate = useNavigate();

    const loadData = async () => {
        setIsLoading(true);
        await getGames(GameTypeEnum.LIVE_CASINO);
        setIsLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleClickPlay = async (item) => {
        const link = `/live-casino/${item.GameType}/${item.GameCode}`;
        navigate(link);
    };

    const filteredGames = () => {
        return games.filter((item) => item.GameName.toLocaleLowerCase().includes(name.toLocaleLowerCase()));
    };

    const showColumns = () => {
        if (width > 1024) return 3;
        if (width <= 1024) return 2;
        return 3;
    };

    return (
        <div className="LiveCasino">
            {isLoading ? (
                <div className="Sport-loading">
                    <img src={loading} alt="" />
                </div>
            ) : (
                <div className="LiveCasino-content">
                    <div className="LiveCasino-games-list">
                        {filteredGames().map((item) => (
                            <div
                                key={item.GameCode}
                                className="LiveCasino-games-list-item"
                                style={{ backgroundImage: `url(${item.ImageUrl})` }}
                                onClick={isMobile && isLoadingGame.length === 0 ? () => handleClickPlay(item) : null}
                            >
                                <span className="LiveCasino-games-list-item-name">{item.GameName}</span>
                                <Button
                                    title={t('play')}
                                    loading={isLoadingGame === item.GameCode}
                                    onClick={() => handleClickPlay(item)}
                                    className="LiveCasino-button"
                                    disabled={isLoadingGame.length !== 0}
                                />
                                {isMobile && isLoadingGame === item.GameCode && (
                                    <div className="LiveCasino-loading-mobile">
                                        <Loading />
                                    </div>
                                )}
                            </div>
                        ))}

                        {/*{filteredGames()*/}
                        {/*    .reduce((acc, item, index) => {*/}
                        {/*        if (index % showColumns() === 0) {*/}
                        {/*            acc.push([]);*/}
                        {/*        }*/}
                        {/*        acc[acc.length - 1].push(item);*/}
                        {/*        return acc;*/}
                        {/*    }, [])*/}
                        {/*    .map((group, groupIndex) => (*/}
                        {/*        <div*/}
                        {/*            className={`LiveCasino-games-list-group ${hoveredIndex === groupIndex ? 'expanded' : ''}`}*/}
                        {/*            key={groupIndex}*/}
                        {/*            onMouseEnter={() => setHoveredIndex(groupIndex)}*/}
                        {/*            onMouseLeave={() => setHoveredIndex(null)}*/}
                        {/*        >*/}
                        {/*            {group.map((item) => (*/}
                        {/*                <div*/}
                        {/*                    className="LiveCasino-games-list-item"*/}
                        {/*                    onClick={isMobile && isLoadingGame.length === 0 ? () => handleClickPlay(item) : null}*/}
                        {/*                    key={item.GameCode}*/}
                        {/*                >*/}
                        {/*                    <div*/}
                        {/*                        className="LiveCasino-image-container"*/}
                        {/*                        style={{ backgroundImage: `url(${item.ImageUrl})` }}*/}
                        {/*                    />*/}
                        {/*                    <span className="LiveCasino-games-list-item-name">{item.GameName}</span>*/}
                        {/*                    <Button*/}
                        {/*                        title={t('play')}*/}
                        {/*                        loading={isLoadingGame === item.GameCode}*/}
                        {/*                        onClick={() => handleClickPlay(item)}*/}
                        {/*                        className="LiveCasino-button"*/}
                        {/*                        disabled={isLoadingGame.length !== 0}*/}
                        {/*                    />*/}
                        {/*                    {isMobile && isLoadingGame === item.GameCode && (*/}
                        {/*                        <div className="LiveCasino-loading-mobile">*/}
                        {/*                            <Loading />*/}
                        {/*                        </div>*/}
                        {/*                    )}*/}
                        {/*                </div>*/}
                        {/*            ))}*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                    </div>
                </div>
            )}
        </div>
    );
}

export default LiveCasino;
