import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import store from 'src/core/store/store';
import i18n from 'src/core/translations/i18n';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </Provider>
    </React.StrictMode>,
);
