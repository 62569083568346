import { t } from 'i18next';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { EnumStatusDocuments, PermissionDictionary } from 'src/common/enum';

interface JwtPayload {
    jwtPayloadData: {
        permissionLevel: number;
    };
}

export const checkPermission = (token: string): boolean => {
    try {
        const decodedToken: JwtPayload = jwtDecode(token);
        const { permissionLevel } = decodedToken.jwtPayloadData;
        if (permissionLevel === PermissionDictionary.USER_PLAYER_PERMISSION_LEVEL) return true;
        return false;
    } catch (error) {
        return false;
    }
};

export const factoryVerificationTitle = (verification: number): string => {
    switch (verification) {
        case EnumStatusDocuments.DOCUMENT_STATUS_APPROVED:
            return t('verified');
        case EnumStatusDocuments.DOCUMENT_STATUS_CREATED:
            return t('on_review');
        case EnumStatusDocuments.DOCUMENT_STATUS_REJECTED:
            return t('rejected');
        default:
            return t('not_verified');
    }
};

export const factoryShowUploaded = (verification: number): boolean => {
    switch (verification) {
        case EnumStatusDocuments.DOCUMENT_STATUS_REJECTED:
        case 0:
            return true;

        default:
            return false;
    }
};

export const dateToTimestamp = (inputDate: Date) => {
    const parsedDate = moment(inputDate);

    const timestamp = parsedDate.valueOf();
    return Math.floor(moment.utc(timestamp).valueOf() / 1000);
};

export const formatUserTimeZone = (inputDate: string) => {
    const parsedDate = moment(inputDate);

    const userTimeZoneOffset = new Date().getTimezoneOffset();

    const formattedDate = parsedDate.utcOffset(userTimeZoneOffset).format();

    return formattedDate;
};
