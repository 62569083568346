import React, { useState, FC } from 'react';
import './changePassword.scss';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { api } from 'src/api/api';
import Button from 'src/components/button/Button';
import Input from 'src/components/input/Input';
import { modalTypes } from 'src/components/modals/Modal';
import { setCloseModalTTT } from 'src/core/store/reducers/appSlice';
import { appDispatch } from 'src/core/store/store';

import { ResponseChangePassword } from './types';

const ChangePassword: FC = (): JSX.Element => {
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [disabled, setDisbaled] = useState<boolean>(false);
    const { t } = useTranslation();

    const loginHandle = async (): Promise<void> => {
        const option = {
            oldPassword,
            newPassword,
            passwordSign: repeatPassword,
        };
        setDisbaled(true);
        const resp: ResponseChangePassword = await api.post(`/user/password/change`, option);
        if (resp.data) {
            NotificationManager.success(`${t('sent_code')}`, '', 3500);
            sessionStorage.removeItem('newPassword');
            appDispatch(setCloseModalTTT(String(modalTypes.CHANGE_PASSWORD)));
        } else {
            setDisbaled(false);
        }
    };

    return (
        <div className="ChangePassword">
            <div
                className="ChangePassword-xmark"
                onClick={() => appDispatch(setCloseModalTTT(String(modalTypes.CHANGE_PASSWORD)))}
            >
                ✕
            </div>
            <span className="ChangePassword-content-title">{t('change_password')}</span>
            <div className="ChangePassword-content">
                <div className="ChangePassword-content-inputs">
                    <Input
                        name="nickname"
                        className="Login-content-inputs-input"
                        label={t('current_password')}
                        placeholder={t('enter_current_password')}
                        value={oldPassword}
                        autoComplete="off"
                        type="password"
                        onChange={(event: { target: HTMLInputElement }) => {
                            setOldPassword(event.target.value);
                        }}
                    />
                    <Input
                        name="password"
                        className="ChangePassword-content-inputs-input"
                        label={t('new_password')}
                        placeholder={t('enter_new_password')}
                        value={newPassword}
                        type="password"
                        onChange={(event: { target: HTMLInputElement }) => {
                            setNewPassword(event.target.value);
                        }}
                    />
                    <Input
                        name="password"
                        className="ChangePassword-content-inputs-input"
                        label={t('repeat_password')}
                        type="password"
                        placeholder={t('repeat_password')}
                        value={repeatPassword}
                        onChange={(event: { target: HTMLInputElement }) => {
                            setRepeatPassword(event.target.value);
                        }}
                    />
                </div>

                <Button
                    className="ChangePassword-content-reset"
                    title={t('save_password')}
                    primary
                    onClick={loginHandle}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default ChangePassword;
