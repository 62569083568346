import React, { useState, useEffect, useRef } from 'react';
import './outlinedDropdown.scss';
import arrowImg from 'src/assets/images/arrow.svg';
import classNames from 'classnames';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Check } from 'src/assets/images/checkMark.svg';

function OutlinedDropdown({
    options,
    onSelect,
    defaultSelected,
    className,
    placeholder = 'Select',
    disabled = false,
    small = false,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
    const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });
    const toggleRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        setSelectedOption(defaultSelected);
    }, [defaultSelected]);

    const toggleDropdown = () => {
        if (disabled) return;
        setIsOpen(!isOpen);
        if (!isOpen) {
            const rect = toggleRef.current.getBoundingClientRect();
            setDropdownPosition({ top: rect.bottom + 6, left: rect.left, width: rect.width });
        }
    };

    const handleOptionSelect = (option) => {
        if (disabled) return;
        if (option.value === selectedOption?.value) return;
        setSelectedOption(option);
        setIsOpen(false);
        if (onSelect) {
            onSelect(option);
        }
    };

    return (
        <div
            className={classNames('outlinedDropdownV2', className, {
                ['outlinedDropdown--toggled']: isOpen,
                ['outlinedDropdown-small']: small,
            })}
            ref={ref}
        >
            <div className="outlinedDropdown-toggleV2" onClick={toggleDropdown} ref={toggleRef}>
                <span className="outlinedDropdown-selected">{selectedOption ? selectedOption?.label : placeholder}</span>
                <img
                    src={isOpen ? arrowImg : arrowImg}
                    alt="arrow"
                    className={classNames('arrowV2', { ['arrow--toggledV2']: isOpen })}
                />
            </div>
            {isOpen && (
                <div
                    className="outlinedDropdown-options-wrapperV2"
                    style={{
                        top: dropdownPosition.top + 10,
                        left: dropdownPosition.left - 20,
                        width: dropdownPosition.width + 40,
                    }}
                >
                    {options.length ? (
                        options.map((option) => (
                            <div
                                className={classNames('outlinedDropdown-optionV2', {
                                    ['outlinedDropdown-optionV2--disabled']: option.value === selectedOption?.value,
                                })}
                                key={option.value}
                                onClick={() => handleOptionSelect(option)}
                            >
                                {option.label}
                                {option.value === selectedOption?.value && <Check />}
                            </div>
                        ))
                    ) : (
                        <span className="outlinedDropdown-nooption">{t('no_options')}</span>
                    )}
                </div>
            )}
        </div>
    );
}

export default OutlinedDropdown;
