/* eslint-disable */
import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import AppLayout from 'src/components/layouts/AppLayout';
import ModalComponent, { modalTypes } from 'src/components/modals/Modal';
import LiveCasino from 'src/views/LiveCasino';
import Main from 'src/views/Main/Main';
import PlayGame from 'src/views/PlayGame';
import Sport from 'src/views/Sport';

function AppRoutes() {
    const isAuthorizedState = useSelector((state: { app: { isAuthorizedState: boolean } }) => state.app.isAuthorizedState);
    const location = useLocation();
    const state = location.state;
    const isMobile = window.innerWidth <= 900;

    return (
        <>
            <Routes location={state?.background || location}>
                <Route
                    path="/"
                    element={
                        <AppLayout>
                            <Main />
                        </AppLayout>
                    }
                />
                {isAuthorizedState && (
                    <>
                        <Route
                            path="/live-casino"
                            element={
                                <AppLayout>
                                    <LiveCasino />
                                </AppLayout>
                            }
                        />
                        <Route
                            path="/sport"
                            element={
                                <AppLayout>
                                    <Sport />
                                </AppLayout>
                            }
                        />
                        <Route
                            path="/live-casino/:gameType/:gameCode"
                            element={
                                <AppLayout>
                                    <PlayGame />
                                </AppLayout>
                            }
                        />
                        <Route
                            path="/chance"
                            element={
                                <AppLayout>
                                    <Main />
                                    <ModalComponent modalType={modalTypes.CHANCE} />
                                </AppLayout>
                            }
                        />
                        <Route
                            path="/chance-olwin"
                            element={
                                <AppLayout>
                                    <Main />
                                    <ModalComponent modalType={modalTypes.OLWIN_CHANCE} />
                                </AppLayout>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <AppLayout>
                                    <Main />
                                    {!isMobile && <ModalComponent modalType={modalTypes.PROFILE} />}
                                </AppLayout>
                            }
                        />
                    </>
                )}
                <Route path="/*" element={<Navigate to="/" replace />} />
            </Routes>
            {state?.background && (
                <Routes>
                    <Route path="/chance" element={<ModalComponent modalType={modalTypes.CHANCE} />} />
                    <Route path="/chance-olwin" element={<ModalComponent modalType={modalTypes.OLWIN_CHANCE} />} />
                    <Route path="/profile" element={!isMobile && <ModalComponent modalType={modalTypes.PROFILE} />} />
                </Routes>
            )}
        </>
    );
}

function AppRouter() {
    return (
        <Router>
            <AppRoutes />
        </Router>
    );
}

export default AppRouter;
