import { api } from 'src/api/api';
import { setGames } from 'src/core/store/reducers/gamesSlice';
import store from 'src/core/store/store';

export const getGames = async (gameType) => {
    const resp = await api.get(`/provider/live-casino/Seamless/getGameList?GameType=${gameType}`);
    if (resp.data) {
        const games = resp.data.ProviderGames.map((item) => ({ ...item, ImageUrl: item.ImageUrl.replace('size=S', 'size=L') }));
        store.dispatch(setGames(games));
    }
};

export const getGame = async (gameType, gameId) => {
    const resp = await api.get(`/provider/live-casino/Seamless/LaunchGame?GameId=${gameId}&GameType=${gameType}`);
    return resp.data;
};
