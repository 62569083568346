import './mobileRightSideMenu.scss';
import arrowImg from 'src/assets/images/arrow.svg';
import photo_profile from 'src/assets/images/user.svg';
import profile from 'src/assets/images/profile.svg';
import copyimg from 'src/assets/images/copy.svg';
import exit from 'src/assets/images/enter.svg';
import wallet_mob from 'src/assets/images/wallet_mob.svg';
import history from 'src/assets/images/history.svg';
import lock from 'src/assets/images/lock.svg';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Upload } from 'src/assets/images/upload.svg';
import { useSwipeable } from 'react-swipeable';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerRightOpen, setOpenModal } from 'src/core/store/reducers/appSlice';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { useCallback } from 'react';
import { modalTypes } from 'src/components/modals/Modal';
import { factoryShowUploaded, factoryVerificationTitle } from '../../utils/helpers';
import { EnumStatusDocuments } from '../../common/enum';

function MobileRightSideMenu() {
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onCopy = useCallback(() => {
        NotificationManager.success(`ID ${t('copied')}`, '', 2000);
        copy('012456');
    }, []);

    const closeDrawerRight = () => {
        dispatch(setDrawerRightOpen(false));
        if (window.history.state && window.history.state.idx > 2) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedRight: closeDrawerRight,
        preventDefaultTouchmoveEvent: true,
        trackMouse: false,
    });

    const openModal = useCallback(
        (type) => {
            if (!type) return;
            dispatch(setOpenModal({ modal: { type } }));
        },
        [dispatch],
    );

    const factoryVerificationStyle = (verification) => {
        switch (verification) {
            case EnumStatusDocuments.DOCUMENT_STATUS_APPROVED:
                return `verification_approve_mobile`;
            case EnumStatusDocuments.DOCUMENT_STATUS_CREATED:
                return 'verification_created_mobile';
            case EnumStatusDocuments.DOCUMENT_STATUS_REJECTED:
                return 'verification_rejected_mobile';
            default:
                return 'verification_not_created_mobile';
        }
    };

    return (
        <div {...swipeHandlers} className="MobileRightSideMenu">
            <div className="MobileRightSideMenu-header">
                <div className="MobileRightSideMenu-goback" onClick={closeDrawerRight}>
                    <img src={arrowImg} alt="arrow" className="MobileRightSideMenu-arrow" />
                    <span>{t('back')}</span>
                </div>
            </div>

            <div className="MobileRightSideMenu-content">
                <div className="MobileRightSideMenu-content-info">
                    <img src={profile} alt="photo_profile" className="MobileRightSideMenu-profile-icon" />
                    <div className="MobileRightSideMenu-content-info-content">
                        <span className="MobileRightSideMenu-content-info-content-name">{user.name}</span>
                        <span className="MobileRightSideMenu-profile-id" onClick={onCopy}>
                            ID:&nbsp;&nbsp;012456 <img src={copyimg} alt="copy" className="MobileRightSideMenu-copy-icon" />
                        </span>
                        <div
                            className={factoryVerificationStyle(user.verification)}
                            onClick={factoryShowUploaded(user.verification) ? () => openModal(modalTypes.UPLOAD_DOCS) : () => {}}
                        >
                            <span>{factoryVerificationTitle(user.verification)}</span>
                            {factoryShowUploaded(user.verification) && (
                                <div className="MobileRightSideMenu-upload">
                                    <Upload />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="MobileRightSideMenu-content-balance" onClick={() => openModal(modalTypes.DEPOSIT)}>
                    <div className="MobileRightSideMenu-content-balance-container">
                        <span>{t('real_balance')}:</span>
                        <span className="MobileRightSideMenu-profile-id">
                            <b>{user.balance ? ((user.balance + user.scedualPaymentBalance) / 100)?.toFixed(2) : 0}</b>&nbsp; USD
                        </span>
                    </div>
                </div>
                <div className="MobileRightSideMenu-content-list">
                    <div onClick={() => openModal(modalTypes.ACCOUNT)} className="MobileRightSideMenu-content-list-item">
                        <img src={photo_profile} alt="account" />
                        <span>{t('account')}</span>
                    </div>
                    <div className="MobileRightSideMenu-content-list-item" onClick={() => openModal(modalTypes.DEPOSIT)}>
                        <img src={wallet_mob} alt="wallet_mob" />
                        <span>{t('balance')}</span>
                    </div>
                    <div className="MobileRightSideMenu-content-list-item" onClick={() => openModal(modalTypes.HISTORY)}>
                        <img src={history} alt="history" />
                        <span>{t('history')}</span>
                    </div>
                    <div className="MobileRightSideMenu-content-list-item" onClick={() => openModal(modalTypes.CHANGE_PASSWORD)}>
                        <img src={lock} alt="lock" />
                        <span>{t('change_password')}</span>
                    </div>
                    <div className="MobileRightSideMenu-content-list-item-exit" onClick={() => openModal(modalTypes.EXIT)}>
                        <img src={exit} alt="exit" />
                        <span>{t('exit')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileRightSideMenu;
