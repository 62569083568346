import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';
import image1 from '../../assets/images/slider/first_deposit.jpg';
import image2 from '../../assets/images/slider/loyalty.jpg';
import image3 from '../../assets/images/slider/monthly_cashback.jpg';
import image4 from '../../assets/images/slider/registration_bonus.jpg';
import image1Mobile from '../../assets/images/slider/mob/first_deposit.jpg';
import image2Mobile from '../../assets/images/slider/mob/loyalty.jpg';
import image3Mobile from '../../assets/images/slider/mob/monthly_cashback.jpg';
import image4Mobile from '../../assets/images/slider/mob/registration_bonus.jpg';
import { useSelector } from 'react-redux';

const Sliders = () => {
    const isAuthorizedState = useSelector((state) => state.app.isAuthorizedState);
    const isMobile = window.innerWidth <= 900;
    const images = [{ src: image1 }, { src: image2 }, { src: image3 }, { src: image4 }];
    const imagesMobile = [{ src: image1Mobile }, { src: image2Mobile }, { src: image3Mobile }, { src: image4Mobile }];

    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div className="Slider" style={!isAuthorizedState ? { width: '100%' } : {}}>
            <Slider {...settings}>
                {(isMobile ? imagesMobile : images).map((image, index) => (
                    <div key={index} className="Slider-item">
                        <img src={image.src} className="Slider-item-image" alt="" />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Sliders;
