import { t } from 'i18next';
import { EnumGender } from '../common/enum';

export const genderOptions = [
    { label: t('male'), value: EnumGender.MALE },
    { label: t('female'), value: EnumGender.FEMALE },
];

export const currencyOptions = [
    { label: 'USDT-TRC20', value: 'USDT-TRC20' },
    // { label: 'USDT-ERC20', value: 'USDT-ERC20' },
];
