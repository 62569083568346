import './login.scss';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { modalTypes } from 'src/components/modals/Modal';
import imageLogin from 'src/assets/images/loginPreview.png';
import olwinLogo from 'src/assets/images/olwin.png';
import Input from 'src/components/inputV2/input';
import { setCloseModal, setOpenModal } from 'src/core/store/reducers/appSlice';
import { translationOptions } from 'src/core/translations/constants';
import Dropdown from 'src/components/dropdown/Dropdown';
import { login } from 'src/core/engines/AppEngine';
import Button from 'src/components/buttonV2/Button';
import CheckBox from 'src/components/checkBoxV2';

function Login() {
    const [username, setUsername] = useState('player');
    const [password, setPassword] = useState('1234567890');
    const [remember, setRemember] = useState(false);
    const [disbaled, setDisbaled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const defaulLanguge = useMemo(() => {
        const lang = localStorage.getItem('locale');
        return translationOptions.find((el) => el.value === lang) || translationOptions[0];
    }, []);

    const loginHandle = async () => {
        setIsLoading(true);
        const option = {
            login: username,
            password,
            remember,
        };
        setDisbaled(true);
        await login(option);
        setIsLoading(false);
        setDisbaled(false);
    };

    const resetPassHandler = () => {
        const modal = {
            type: modalTypes.RESET_PASSWORD,
        };
        dispatch(setOpenModal({ modal }));
    };

    const changeLanguageHandler = useCallback(
        (lang) => {
            localStorage.setItem('locale', lang);
            void i18n.changeLanguage(lang);
        },
        [i18n],
    );

    return (
        <div className="Login">
            <div className="Login-header">
                <img src={imageLogin} alt="" />
                <img src={olwinLogo} alt="" className="Login-header-logo" />
            </div>
            <div className="Login-content">
                <div className="Login-content-title">
                    <p>{t('signin')}</p>
                    <Dropdown
                        onSelect={({ value }) => {
                            changeLanguageHandler(value);
                        }}
                        options={translationOptions}
                        defaultSelected={defaulLanguge}
                        className="Login-dropdown"
                    />
                </div>
                <div className="Login-content-inputs">
                    <Input
                        name="nickname"
                        className="Login-content-inputs-input"
                        placeholder={t('user_name_input')}
                        value={username}
                        onChange={({ target }) => {
                            setUsername(target.value.toLowerCase());
                        }}
                    />
                    <Input
                        name="password"
                        className="Login-content-inputs-input"
                        placeholder={t('enter_password')}
                        value={password}
                        type="password"
                        onChange={({ target }) => {
                            setPassword(target.value);
                        }}
                    />
                </div>
                <div className="Login-content-checkboxs">
                    <CheckBox
                        className="Login-content-checkbox"
                        label={t('remember')}
                        checked={remember}
                        onChange={() => {
                            setRemember(!remember);
                        }}
                    />
                </div>
                <Button
                    className="Login-content-signin"
                    title={t('signin')}
                    primary
                    onClick={loginHandle}
                    loading={isLoading}
                    disbaled={disbaled}
                />
                {/* <span className="Login-content-forgot" onClick={resetPassHandler}>
                    {t('forgot_password')}
                </span> */}
            </div>
        </div>
    );
}

export default Login;
