import './input.scss';
import { ReactComponent as NotShow } from 'src/assets/images/not-show.svg';
import { ReactComponent as Show } from 'src/assets/images/show.svg';
import classNames from 'classnames';

function Input({
    placeholder = '',
    className,
    value = '',
    onChange = () => '',
    label = '',
    hideLabel = false,
    error = null,
    id = '',
    type = 'text',
    readOnly = false,
    secondLabel = '',
    small = false,
    icon = null,
    password = false,
    changeType = null,
}) {
    return (
        <div className={classNames(error ? 'inputErrorV2' : 'inputV2', className, { ['input-small']: small })}>
            {!hideLabel && (
                <span>
                    {label} {secondLabel.length !== 0 ? <span className="second-labelV2">{secondLabel}</span> : null}
                </span>
            )}
            {icon ? (
                <div className="input-with-icon">
                    {icon}
                    <input placeholder={placeholder} value={value} onChange={onChange} type={type} id={id} readOnly={readOnly} />
                </div>
            ) : (
                <input placeholder={placeholder} value={value} onChange={onChange} type={type} id={id} readOnly={readOnly} />
            )}
            {error && <span className={classNames('error_messageV2', className)}>{error}</span>}
            {password ? (
                type === 'password' ? (
                    <NotShow className="icon-password" onClick={() => changeType('text')} />
                ) : (
                    <Show className="icon-password" onClick={() => changeType('password')} />
                )
            ) : null}
        </div>
    );
}

export default Input;
